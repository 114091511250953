
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';

export default [
  {
    items: [
      {
        title: 'Blog',
        href: '/blog',
        icon: HomeIcon,
        items: [
          {
            title: 'Blog',
            href: '/blog'
          },
          {
            title: 'Agregar Blog',
            href: '/add-blog'
          },
        ]
      },
      {
        title: 'Usuarios',
        href: '/company',
        icon: BarChartIcon,
        items: [
          {
            title: 'Usuarios',
            href: '/users'
          },
          {
            title: 'Agregar Usuario',
            href: '/add-user'
          },
          {
            title: 'Concurso',
            href: '/concurso'
          },
          {
            title: 'Imagenes',
            href: '/imagenes'
          },
          {
            title: 'Notificacion',
            href: '/push'
          }
        ]
      }
    ]
  },
];
